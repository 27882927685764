import React from 'react';

const PopupContent = ({ togglePopup, condition }) => {
  console.log("popupcondition", condition.conditionId);

  return (
    <div className="popup">
      <div className="popup-content">
        {condition.conditionId === 605 ? (
          <table>
          <tbody>
            <tr>
              <td>
                <strong>Scrum Master:</strong><br />
                <div className='popup_content'>“Welcome, everyone. Let’s begin our sprint retrospective. Our goal today is to reflect on the past sprint and identify what we can improve moving forward. Let’s create a safe space where everyone feels comfortable sharing their thoughts.”</div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Fred Saville (Developer):</strong><br />
                <div className='popup_content'>“I’ll start. I think our daily stand-ups have been very effective. They kept everyone on the same page.”</div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Product Owner:</strong><br />
                <div className='popup_content'>“I agree, but I also feel that our backlog refinement needs improvement. We had some stories that weren’t well-defined.”</div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Scrum Master:</strong><br />
                <div className='popup_content'>“Good points. Let’s note these down. Now, let’s talk about what didn’t go well. Any thoughts?”</div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Kamal Amin (Tester):</strong><br />
                <div className='popup_content'>"As a tester, I feel like I am blamed for the stories not getting done. You guys are giving me stories with too many defects and incomplete work that doesn’t meet the acceptance criteria. Don’t you read the stories before you code?</div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Fred Saville (Developer):</strong><br />
                <div className='popup_content'>"Hey, I have deadlines, too, AND yes I do read the stories! Testing is the easy part!
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Scrum Master:</strong><br />
                <div className='popup_content'>Facilitator/Scrum Master: “I can see this topic is generating strong feelings. Let’s take a five-minute break to step back and reflect.”</div>
              </td>
            </tr>
          </tbody>
        </table>
        ) 
        : condition.conditionId === 606 ? (
          <table>
            <tr>
              <td>
              <strong>Kamal Amin (Tester):</strong><br />
                  <div className='popup_content'>“I apologize if I came across as harsh. My intention was to address the issue, not to criticize anyone personally.”
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <strong>Fred Saville (Developer):</strong><br />
                  <div className='popup_content'>“I appreciate that, and I’m sorry for getting defensive. Let’s try to find a solution together.”
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <strong>Scrum Master:</strong><br />
                  <div className='popup_content'>“Thank you both for sharing. Now, let’s discuss the issue with a fresh perspective. What are the underlying concerns, and how can we address them?”
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <strong>Product Owner: Farley Gnu</strong><br />
                  <div className='popup_content'>“The main concern seems to be the timing of deliverables. Can we agree on a process that ensures timely delivery without overburdening any team member?”
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <strong>Maria Tidwell</strong><br />
                  <div className='popup_content'>“I think we can. What if we set interim deadlines for smaller tasks to avoid last-minute rushes?”
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <strong>Scrum Master:</strong><br />
                  <div className='popup_content'>“That sounds like a constructive approach. Let’s outline what that would look like and agree on some action items.”
                  </div>
              </td>
            </tr>
            <tr>
              <td>
              <strong>Kamal Amin (Tester):</strong><br />
                  <div className='popup_content'>“I’m willing to work with the developers to create a more detailed schedule that works for everyone.”
                  </div>
              </td>
            </tr>
            </table>
        ) 
        : condition.conditionId === 612 ? (
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Scrum Master:</strong><br />
                  <div className='popup_content'>“Good morning, team! I recognize that working remotely can sometimes make us feel disconnected. To foster better connections and interaction among us, we’re going to start our retrospective with a 'Virtual Coffee Chat'. Think of it as a casual conversation where we get to know a bit more about each other in a relaxed setting. Are you all up for it?”</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Nick Zimmerman (Developer):</strong><br />
                  <div className='popup_content'>Sure, sounds good.</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Sladi Petrov (Developer):</strong><br />
                  <div className='popup_content'>That could be interesting. I’m in.</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Scrum Master:</strong><br />
                  <div className='popup_content'> Great! Each of us will share a fact about our lives outside of work, something interesting we’re passionate about, or a recent experience. It’s a wonderful way to learn more about each other. I’ll go first to break the ice.</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Scrum Master:</strong><br />
                  <div className='popup_content'>So, one fun fact about me is that I’ve recently taken up baking and I’ve been experimenting with different bread recipes during the weekends. What about you, Nick?</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Nick Zimmerman (Developer):</strong><br />
                  <div className='popup_content'>That's interesting! Let's see, I’ve started learning to play the guitar. It’s been quite a challenge but also very rewarding.</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Scrum Master:</strong><br />
                  <div className='popup_content'>That’s awesome! Sladi, your turn. Share something cool with us!</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Sladi Petrov (Developer):</strong><br />
                  <div className='popup_content'> Well, I’ve been really interested in space and the stars for as long as I can remember. I’m actually taking an online course about space exploration and dreaming of future travels to the Moon or Mars.</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Scrum Master:</strong><br />
                  <div className='popup_content'>That sounds amazing, Sladi! Space exploration is such a fascinating topic. I’d love to hear more about your course sometime.</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Fred Saville (Developer):</strong><br />
                  <div className='popup_content'>That’s really cool. I enjoy stargazing a lot myself, though I enjoy the wide open spaces on Earth. I’ve been hiking a lot lately. It’s a great way to clear my head and stay active.</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Scrum Master:</strong><br />
                  <div className='popup_content'>Great! Would anyone else like to share?</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Yee Lee (Developer):</strong><br />
                  <div className='popup_content'>I’m not comfortable sharing personal information.</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Scrum Master:</strong><br />
                  <div className='popup_content'>That’s completely understandable. You can share whatever you’re comfortable with.</div>
                </td>
              </tr>
            </tbody>
          </table>
        ) 
        : condition.conditionId === 613 ? (
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Scrum Master:</strong><br />
                  <div className='popup_content'>Now, let’s pivot to our retrospective. How can we leverage these personal interests and strengths to improve our teamwork and collaboration? For example, Nick, your perseverance in learning the guitar might inspire discipline in our sprint goals.</div>
                </td>
                </tr>
                <tr>
                <td>
                  <strong>Nick Zimmerman(developer):</strong><br />
                  <div className='popup_content'>I see how that can be applicable. Consistency is key both in learning an instrument and in agile practices</div>
                </td>
                </tr>
                <tr>
                <td>
                  <strong> Scrum Master:</strong><br />
                  <div className='popup_content'> Exactly! And Sladi, your passion for space exploration reminds us of the importance of long-term vision and goal-setting, which is critical for our project success.</div>
                </td>
                </tr>
                <tr>
                <td>
                  <strong>Sladi Petrov:(developer):</strong><br />
                  <div className='popup_content'> Yes, having a clear vision is crucial, whether it's for a sprint or exploring space!</div>
                </td>
                </tr>
                <tr>
                <td>
                  <strong>Scrum Master:</strong><br />
                  <div className='popup_content'> Fantastic! Let’s keep these insights in mind as we move forward with our retrospective today. Remember, these exercises are designed not just to be fun, but to build stronger and more effective teams. Your input and engagement make all the difference.</div>
                </td>
              </tr>
            </tbody>
          </table>
        ) 
        : condition.conditionId === 618 ? (
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Scrum Master:</strong><br />
                  <div className='popup_content'>Good morning, team! Today, we’re going to focus our
                  retrospective on an essential skill that can significantly
                  improve our workflow: writing better user stories. This will
                  help us deliver higher-quality work and ensure we address
                  our users’ needs effectively. Let’s start by reflecting on our
                  current practices.
                  </div>
                </td>
                </tr>
                <tr>
                <td>
                  <strong>Sladi Petrov:</strong><br />
                  <div className='popup_content'>That’s a great topic! I have seen issues during our last 3 sprints.</div>
                </td>
                </tr>
                <tr>
                <td>
                  <strong> Scrum Master:</strong><br />
                  <div className='popup_content'> Thanks, Sladi. I appreciate that you have come prepared
                  with ideas. Let’s all take a moment to think about how we’ve
                  been writing user stories. What went well? What didn’t go so
                  well? And what can we improve? We’ll use a "Start, Stop,
                  Continue" format to capture our thoughts.
                  </div>
                </td>
                </tr>
                <tr>
                <td>
                  <strong>Scrum Master</strong><br />
                  <div className='popup_content'> Alright, let’s start with what we should continue doing. Who’d like to share?</div>
                </td>
                </tr>
                <tr>
                <td>
                  <strong>Fred Seville</strong><br />
                  <div className='popup_content'> I think we’ve been good at clearly defining our acceptance
                  criteria. It helps ensure everyone knows when the story is
                  done.
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Nick Zimmerman</strong><br />
                  <div className='popup_content'> I agree. Also, having our Product Owner readily available for clarifications has been beneficial.
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Scrum Master</strong><br />
                  <div className='popup_content'>Great! Let’s make sure we keep those practices. Now, let’s talk about what we should stop doing.
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Sladi Petrov</strong><br />
                  <div className='popup_content'>Sometimes, our stories are too broad and encompass
                  multiple tasks, making them hard to complete within a sprint.
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Maria Tidwell</strong><br />
                  <div className='popup_content'>Yes, and occasionally, we jump straight to technical details without fully understanding the users’ needs.
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Scrum Master</strong><br />
                  <div className='popup_content'>These are important observations. Writing overly broad
                      stories and skipping user needs can indeed create
                      bottlenecks. Now, what should we start doing?
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Nick Zimmerman</strong><br />
                  <div className='popup_content'>We should focus more on capturing the user’s perspective in
                      our stories. An approach like the &quot;INVEST&quot; criteria could
                      help.
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        ) 
        : condition.conditionId === 619 ? (
          <table>
            <tbody>
            <tr>
                <td>
                  <strong>Scrum Master</strong><br />
                  <div className='popup_content'>Let’s do a quick review of what the "INVES" model means. Chime in if you know any!<br></br>
                  <b>Independent:</b> Stories should be self-contained, without dependencies on
                  other stories.
                  </div>
                </td>
            </tr>
            <tr>
                <td>
                  <strong>Nick Zimmerman (developer)</strong><br />
                  <div className='popup_content'><b>Negotiable: </b>Details can be revised as the team gets more insights.
                 
                  </div>
                </td>
            </tr>
            <tr>
                <td>
                  <strong>Sladi Petrov:(developer)</strong><br />
                  <div className='popup_content'><b>Valuable: </b>Stories must deliver value to the user.
                 
                  </div>
                </td>
            </tr>
            <tr>
                <td>
                  <strong>Maria Tidwell</strong><br />
                  <div className='popup_content'><b>Estimable: </b>The team should be able to estimate the size of the story.
                 
                  </div>
                </td>
            </tr>
            <tr>
                <td>
                  <strong>Fred Seville</strong><br />
                  <div className='popup_content'><b>Small: </b>Stories should be small enough to be completed within a sprint.
                 
                  </div>
                </td>
            </tr>
            <tr>
                <td>
                  <strong>Scrum Master</strong><br />
                  <div className='popup_content'><b>Excellent! The last one is Testable: </b>There should be clear acceptance criteria to verify the story is complete.<br></br>
                  <strong>PAUSE</strong><br /><br></br>
                  Let’s practice. I’ll describe a scenario, and we’ll create an INVEST- compliant user story together.
                  <strong>PAUSE</strong><br /><br></br>
                  As an online bookstore user, I want to read reviews so that I can decide which books to buy.<br></br>
                  Based on this scenario, what would a good user story look like?
                  </div>
                </td>
            </tr>
            <tr>
                <td>
                  <strong>Fred Seville</strong><br />
                  <div className='popup_content'>How about: "As a user, I want to view book reviews on each book’s page
                  so that I can make informed purchase decisions."
                 
                  </div>
                </td>
            </tr>
            <tr>
                <td>
                  <strong>Scrum Master</strong><br />
                  <div className='popup_content'>Great start, Fred! Now, let’s make sure it meets the INVEST criteria. Is it
                  independent?
                 
                  </div>
                </td>
            </tr>
            <tr>
                <td>
                  <strong>Nick Zimmerman</strong><br />
                  <div className='popup_content'>Yes, it can be developed without depending on other stories.
                 
                  </div>
                </td>
            </tr>
            <tr>
                <td>
                  <strong>Scrum Master</strong><br />
                  <div className='popup_content'>Is it negotiable? Can details be refined?
                  </div>
                </td>
            </tr>
            <tr>
                <td>
                  <strong>Sladi Petrov:</strong><br />
                  <div className='popup_content'>Yes, we can decide on specifics like how many reviews to show or what format.
                  </div>
                </td>
            </tr>
            <tr>
                <td>
                  <strong>Maria Tidwell:</strong><br />
                  <div className='popup_content'>And it is valuable. It absolutely helps users make purchase decisions.
                  </div>
                </td>
            </tr>
            <tr>
                <td>
                  <strong>Scrum Master:</strong><br />
                  <div className='popup_content'>That’s a good observation, Maria. Can we estimate it?
                  </div>
                </td>
            </tr>
            <tr>
                <td>
                  <strong>Fred Seville:</strong><br />
                  <div className='popup_content'>Yes, we can break down the tasks needed to implement it.
                  </div>
                </td>
            </tr>
            <tr>
                <td>
                  <strong>Nick Zimmerman:</strong><br />
                  <div className='popup_content'>And it’s small enough to complete in a sprint if we keep the scope focused on displaying existing reviews without adding complex features.
                  </div>
                </td>
            </tr>
            <tr>
                <td>
                  <strong>Scrum Master:</strong><br />
                  <div className='popup_content'>And finally, is it testable?
                  </div>
                </td>
            </tr>
            <tr>
                <td>
                  <strong>Sladi Petrov:</strong><br />
                  <div className='popup_content'>Yes, we can write acceptance criteria like "Given the user is on a book page, when the user scrolls down, they should see a list of reviews".
                  </div>
                </td>
            </tr>
            <tr>
                <td>
                  <strong>Scrum Master:</strong><br />
                  <div className='popup_content'>Excellent ! We’ve created an INVEST-compliant user story. Let’s do this for our future stories to ensure they are clear, concise, and valuable. I'll
                    summarize our learnings and actionable steps for us to follow.<br></br>
                    1. Continue clearly defining acceptance criteria.<br></br>
                    2. Stop writing overly broad and detailed technical stories without understanding the user’s need.<br></br>
                    3. Start applying the INVEST model to our user stories.<br></br>
                  </div>
                </td>
            </tr>
            </tbody>
          </table>

        ) 
        : condition.conditionId === 624 ? (

          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Scrum Master:</strong>
                  <div className='popup_content'>"Hey team! Today, we’re going to navigate our retrospective using the Sailboat analogy. This method helps us visualize our journey and uncover insights about our progress."</div>
                </td>
              </tr><br></br>
              <tr>
                <td>

                  <strong>Maria Tidwell:</strong>
                  <div className='popup_content'>"That sounds interesting! How do we get started?"</div>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <strong>Scrum Master:</strong>
                  <div className='popup_content'>"Great question! Let’s imagine our project as a sailboat on a voyage toward our goals. The island represents the goal or vision we’re striving to reach. There are four key elements:</div>
                </td><br></br>
              </tr>
              <tr>
                <ol>
                  <li><strong>The wind</strong> symbolizes the factors propelling us forward, such as strengths, successful practices, or positive events.</li>
                  <li><strong>The anchors</strong> symbolize the issues or obstacles holding us back, causing delays or challenges.</li>
                  <li><strong>The rocks</strong> represent the risks or potential pitfalls that could hinder our progress.</li>
                  <li><strong>And the island</strong> is our ultimate goal or vision we aim to reach."</li>
                </ol>
              </tr>
            </tbody>
          </table>


        ) 
        : condition.conditionId === 625 ? (
          <table>
            <tbody>
              <tr>
                <td>
                  <div className='popup_content'><strong>Scrum Master:</strong> "First, let’s talk about the wind – those factors that have been propelling us forward."</div>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <div className='popup_content'><strong>Scrum Master:</strong> "Sladi, your proactive communication has been like a strong
                    wind in our sails. Can you share how you keep everyone informed?"</div>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <div className='popup_content'><strong>Sladi:</strong> "Thanks for saying that! I’ve found a few strategies that really help keep the team aligned and informed. Besides the daily stand-ups, I schedule regular check-ins with team members to discuss their progress, answer questions, and offer support. These are more informal and allow for deeper conversations. Also, instead of waiting for issues to escalate, I proactively share updates on the sprint's progress, upcoming deadlines, and any changes in scope. This helps manage expectations and keeps everyone prepared.</div>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <div className='popup_content'><strong>Scrum Master:</strong> "That's fantastic, Sladi! Your proactive approach undoubtedly helps to keep our sailboat on course toward the island. It's a perfect example of how strong communication acts as a wind propelling us forward."</div>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <div className='popup_content'><strong>Nick Zimmerman:</strong> "I agree! Sladi, during the latest sprint, flagging a security risk early helped ensure our progress wasn’t disrupted."</div>
                </td>
              </tr>
              <br></br>
            </tbody>
          </table>
        ) 
        : condition.conditionId === 626 ? (
          <table>
            <tbody>
            <tr>
                <td>
                  <div className='popup_content'><strong>Maria Tidwell:</strong> "Kamal, our test schedules sometimes cause bottlenecks.
                  Ideas for improvement?"</div>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <div className='popup_content'><strong>Kamal Malke:</strong> "I have been thinking about this problem and want to
                  introduce automated tests for repetitive tasks to reduce last-minute rushes."</div>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <div className='popup_content'><strong>Fred Seville:</strong> "I can help with that, Kamal. Also, task prioritization can be another anchor. We could streamline our tasks better by using a method to ensure we focus on the most critical tasks."</div>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <div className='popup_content'><strong>Scrum Master:</strong> Good idea, Fred!</div>
                </td>
              </tr>
              <br></br>
          </tbody>
          </table>
        ) 
        : condition.conditionId === 627 ? (
          <table>
            <tbody>
            <tr>
                <td>
                  <div className='popup_content'><strong>Sladi Petrav:</strong> "I heard our Product Owner is going on a 3-week vacation. There might be delays in getting feedback. This could cause us to lose
                  valuable development time if we're waiting for decisions or clarifications."</div>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <div className='popup_content'><strong>Scrum Master:</strong> "That is a valid concern, Sladi. Timely feedback is crucial.
              Farley, as our Product Owner, how do you think we could manage or mitigate
              this risk?"</div>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <div className='popup_content'><strong>Farley Gnu:</strong> "That is a valid concern, Sladi. Timely feedback is crucial.
              Farley, as our Product Owner, how do you think we could manage or mitigate
              this risk?"</div>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <div className='popup_content'><strong>Farley Gnu:</strong> "Great point. I have a business analyst available to act as my
              proxy. We will evaluate the backlog using a new technique called
              Prioritization by Value. We will rank backlog items in order of importance
              based on business value, customer needs, and strategic alignment. Highest-
              value items are placed at the top."</div>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <div className='popup_content'><strong>Scrum Master:</strong> "Excellent plan. Having a well-prepared and dedicated
              resource ensures clarity in our backlog. This way, we can navigate this
              potential rock and maintain our momentum towards our sprint goals."</div>
                </td>
              </tr>
              <br></br>
          </tbody>
          </table>
        ) 
        : condition.conditionId === 628 ? (
          <table>
            <tbody>
            <tr>
                <td>
                  <div className='popup_content'><strong>Nick Zimmerman:</strong> "Our primary goal is to deliver all sprints successfully and on time, achieving high engagement."</div>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <div className='popup_content'><strong>Maria Tidwell:</strong> "Improving our collaboration and communication to enhance productivity is also key."</div>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <div className='popup_content'><strong>Scrum Master:</strong> "Exactly! Our goal is like the island in the distance, guiding our efforts. Let’s convert our discussion into actionable items. How can we use our insights to reach our goal more effectively?"</div>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <div className='popup_content'><strong>Maria Tidwell:</strong> "Collaborating with the new Business Analyst will be key to prioritizing our work."</div>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <div className='popup_content'><strong>Fred Seville:</strong> "Creating automated testing for repetitive tasks will give us buffer time."</div>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <div className='popup_content'><strong>Scrum Master:</strong> "Great discussion, team! Our reflections have identified key factors propelling us forward, challenges holding us back, risks to be aware of, and clear goals to aim for. Continuous improvement is our target. Let’s keep our sailboat steady and on course. Thank you for your valuable input."</div>
                </td>
              </tr>
              <br></br>
          </tbody>
          </table>
        ) 
        : condition.conditionId === 631 ? (
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Scrum Master:</strong><br />
                  <div className='popup_content'>Good morning, everyone! Let's start our stand up. Remember, we have 15 minutes to cover what we did yesterday, what we plan to do today, and any blockers.</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Sladi:</strong><br />
                  <div className='popup_content'>Yesterday, I finished my part of an assigned story. Today, I'll start testing it to make sure it works well. I don’t have any obstacles right now.</div>
              </td>
              </tr>
              <tr>
                <td>
                  <strong>Fred Saville:</strong><br />
                  <div className='popup_content'>I was focused on improving acceptance criteria. Today, I'll work on gathering more user feedback to make it better. I need some insights from our product owner regarding specific feedback points.</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Farley Gnu:</strong><br />
                  <div className='popup_content'>I'll provide those insights right after the stand up.</div>
                </td>
              </tr>
              <tr>
                  <td>
                    <strong>Maria:</strong><br />
                    <div className='popup_content'>I completed the prioritized process documentation yesterday. Today, I'll be assisting with testing where needed. A blocker for me is that I'm waiting on feedback from Sladi on the process documentation.</div>
                  </td>
              </tr>
              <tr>
                <td>
                    <strong>Sladi:</strong><br />
                    <div className='popup_content'>I’ll review the documentation after the stand up and provide the feedback you need.</div>
                </td>
              </tr>
            </tbody>
          </table> 
        ) 
        : condition.conditionId === 641 ? (
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Scrum Master:</strong><br />
                  <div className='popup_content'>Good morning, everyone! Let's gather around the Kanban board and review our workflow We’ll go column by column, discussing the status of each task.</div>
                  <div className='popup_content'>We have three tasks here in the to-do column. Fred, are you ready to start on the update task today?</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Fred Saville:</strong><br />
                  <div className='popup_content'>Yes, I'll be starting on that right after the stand-up.</div>
              </td>
              </tr>
              <tr>
                <td>
                  <strong>Scrum Master:</strong><br />
                  <div className='popup_content'>Great, let’s look at the in-progress column.  Maria, how's the initial testing going?</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Maria Tidwell:</strong><br />
                  <div className='popup_content'>The preliminary tests are underway. I should have preliminary results by this afternoon.</div>
                </td>
              </tr>
              <tr>
                  <td>
                    <strong>Scrum Master:</strong><br />
                    <div className='popup_content'>Have you experienced any blockers, Maria?</div>
                  </td>
              </tr>
              <tr>
                <td>
                    <strong>Maria Tidwell:</strong><br />
                    <div className='popup_content'>No blockers currently, but I might need assistance from Sladi later today.</div>
                </td>
              </tr>
              <tr>
                <td>
                    <strong>Scrum Master:</strong><br />
                    <div className='popup_content'>OK, check-in with Sladi when she arrives. Katina, I see your task on automated tests is ready for review. Who can pick this up?</div>
                </td>
              </tr>
              <tr>
                <td>
                    <strong>Yee Lee:</strong><br />
                    <div className='popup_content'>I guess I can review the automated tests after finishing my current task.</div>
                </td>
              </tr>
              <tr>
                <td>
                    <strong>Scrum Master:</strong><br />
                    <div className='popup_content'>Thanks for stepping up, Yee.  Hey! I see the bug fixes are completed and moved to our Done column!</div>
                </td>
              </tr>
            </tbody>
          </table> 
        ) 
        : condition.conditionId === 651 ? (
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Scrum Master:</strong><br />
                  <div className='popup_content'>Good morning, team. We'll go round-robin today, starting with Fred and moving in alphabetical order. Please share what you worked on yesterday, what you're focusing on today, and any assistance you need.</div>              
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Fred Saville:</strong><br />
                  <div className='popup_content'>Yesterday, I worked on bug fixes. Today, I’ll be working on performance enhancements. I might need help from Maria on optimization.</div>
              </td>
              </tr>
              <tr>
                <td>
                  <strong>Scrum Master:</strong><br />
                  <div className='popup_content'>Sounds good, Fred.  Let me know if you and Maria need any assistance.</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Kamal:</strong><br />
                  <div className='popup_content'>I was focused on updating user stories based on customer feedback. Today, I am meeting with Roxie, the business analyst filling in for our Product owner.  As long as I get clarification and priorities defined, I don’t see any blockers.</div>
                </td>
              </tr>
              <tr>
                  <td>
                    <strong>Scrum Master:</strong><br />
                    <div className='popup_content'>Thanks, Kamal.  Keep me in the loop. We will need the updated stories for our backlog refinement meeting next week. I’ll note the dependency on the board, so we don’t forget.</div>
                  </td>
              </tr>
              <tr>
                <td>
                    <strong>Maria Tidwell:</strong><br />
                    <div className='popup_content'>I completed the latest improvements from the Product Owner’s proxy yesterday and will be working on the integration today. I'll need some cooperation from Sladi to help build the test automation scripts.</div>
                </td>
              </tr>
              <tr>
                <td>
                    <strong>Sladi:</strong><br />
                    <div className='popup_content'>I anticipated that, Maria. I began writing automated tests for the new features yesterday. I’ll continue with those today. I'll set up a meeting for us to review. No blockers from my side.</div>
                </td>
              </tr>
              <tr>
                <td>
                    <strong>Scrum Master:</strong><br />
                    <div className='popup_content'>Next? Next? Yee, it is your turn.</div>
                </td>
              </tr>
              <tr>
                <td>
                    <strong>Yee Lee:</strong><br />
                    <div className='popup_content'>Sorry, I got distracted.  It’s not easy always being last in the order.</div>
                </td>
              </tr>
            </tbody>
          </table> 
        ) 
        : null}
      </div>
      <button className="close-btn btn btn-primary" onClick={togglePopup}>Close</button>
    </div>
  );
};

export default PopupContent;
